<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-row>
      <b-col
        cols="12"
        sm="6"
      >
        <b-card>
          <ValidationObserver
            ref="form"
            v-slot="{ handleSubmit }"
          >
            <form
              novalidate
              role="form"
              @submit.prevent="handleSubmit(onConfirmAction)"
            >
              <ValidationProvider
                #default="{ errors }"
                name="current password"
                vid="current_password"
                rules="required"
              >
                <b-form-group>
                  <label for="current_password">Current Password</label>
                  <b-input
                    id="current_password"
                    v-model="credentials.current_password"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter current password"
                    :disabled="isBusy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                #default="{ errors }"
                name="new password"
                vid="password"
                rules="required|min:8|confirmed:password_confirmation"
              >
                <b-form-group>
                  <label for="password">New Password</label>
                  <b-input
                    id="password"
                    v-model="credentials.password"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="enter new password"
                    :disabled="isBusy"
                    data-vv-as="password-confirmation"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                #default="{ errors }"
                name="confirm password"
                vid="password_confirmation"
                rules="required"
              >
                <b-form-group>
                  <label for="password">Confirm New Password</label>
                  <b-input
                    id="password_confirmation"
                    v-model="credentials.password_confirmation"
                    type="password"
                    :state="errors.length > 0 ? false : null"
                    placeholder="confirm new password"
                    :disabled="isBusy"
                  />
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
              <div class="d-inline-block w-100">
                <b-button
                  variant="success"
                  type="submit"
                  :disabled="isBusy"
                >
                  Update Password
                </b-button>
              </div>
            </form>
          </ValidationObserver>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { AxiosError, SharedAuthService } from '@/services'

export default {
  name: 'SharedChangePassword',

  middleware: ['auth'],

  metaInfo () {
    return {
      title: 'Change Password'
    }
  },

  data () {
    return {
      isBusy: false,
      credentials: {
        current_password: '',
        password: '',
        password_confirmation: ''
      }
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async onConfirmAction () {
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Do you really want to change password?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPostSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        }
      })
    },

    async onFormPostSubmit () {
      return new Promise((resolve, reject) => {
        this.isBusy = true
        SharedAuthService.ChangePassword(this.credentials).then(response => {
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: response.data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          }).then(() => {
            this.credentials.current_password = ''
            this.credentials.password = ''
            this.credentials.password_confirmation = ''
            this.$refs.form.reset()
            this.isBusy = false
          })
        }).catch(error => {
          this.isBusy = false
          if (error instanceof AxiosError) {
            if (error.code === 422) {
              this.$refs.form.setErrors(error.message)
              resolve(error)
            }
          }
        })
      })
    }
  }

}
</script>
